<template>
    <v-content>
        <div class="container">
            <v-breadcrumbs :items="breadcrumbItems" large></v-breadcrumbs>
            <div slot="table-actions">
                <div class="my-1">
                    <v-btn @click.stop="addModal" color="primary">{{$t('air_packages_form')}}</v-btn>
                </div>
            </div>
            <vue-good-table :columns="columns"
                            :pagination-options="{ enabled: true,mode: 'records' }"
                            :rows="rows"
                            :totalRows="customerData.totalRecords"
                            @on-column-filter="onColumnFilter"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            @on-sort-change="onSortChange"
                            mode="remote"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'flightDateTime'">
                        <span>{{props.row.flightDateTime | moment("DD-MMM-YYYY, h:mm:ss a")}}</span>
                    </span>
                    <span v-else-if="props.column.field == 'actions'">
                        <v-icon @click.stop="editItem(props.row)" class="mr-2" small>edit</v-icon>
                        <v-icon @click="deleteItemRoom(props.row)" small>delete</v-icon>
                    </span>
                </template>
            </vue-good-table>
            <v-dialog
                    max-width="590"
                    persistent
                    transition="dialog-bottom-transition"
                    v-model="snackbar.formModal"
            >
                <v-card>
                    <AirPackagesForm @dialogueClose="dialogueClose"
                                     @formResponse="formResponse"
                                     v-if="snackbar.createFormComponent"
                    ></AirPackagesForm>
                    <AirPackagesEditForm :editItemIndex="customerData.editItemIndex"
                                         @dialogueClose="dialogueClose"
                                         @formResponse="formResponse"
                                         v-if="snackbar.editFormComponent"
                    ></AirPackagesEditForm>
                </v-card>
            </v-dialog>
            <v-dialog max-width="500px" v-model="snackbar.dialogDelete">
                <deleteListData :deleteFormData="customerData.deleteFormData"
                                @formResponse="onResponseDelete"></deleteListData>
            </v-dialog>
            <v-snackbar
                    :color="snackbar.color"
                    :timeout="snackbar.timeout"
                    top
                    v-model="snackbar.snackbar"
            >
                {{ snackbar.text }}
                <v-btn
                        @click="snackbar.snackbar = false"
                        dark
                        text
                >
                    Close
                </v-btn>
            </v-snackbar>
        </div>
    </v-content>
</template>

<script>
    import axios from "axios";
    import AirPackagesForm from "./AirPackagesForm";
    import AirPackagesEditForm from "./AirPackagesEditForm";
    import deleteListData from "@/components/deleteModal";

    export default {
        name: "AirPackagesList",
        components: {
            'AirPackagesForm': AirPackagesForm,
            'AirPackagesEditForm': AirPackagesEditForm,
            'deleteListData': deleteListData
        },
        methods: {
            updateParams(newProps) {
                console.log('Prop: ', newProps)
                this.serverParams = Object.assign({}, this.serverParams, newProps);
                console.log('Server Params:', this.serverParams)
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.customerData.editItemIndex = item.packageID

            },
            deleteItemRoom(item) {
                console.log(item);
                this.snackbar.dialogDelete = true;
                this.customerData.deleteFormData.url = "Aviation/DeleteAirPackageAsync/" + item.packageID;
                this.loadItems();
            },
            onResponseDelete() {
                this.snackbar.dialogDelete = false;
                this.loadItems();
            },
            formResponse(responseObj) {
                this.snackbar.color = responseObj.status ? 'success' : 'red'
                this.snackbar.text = responseObj.text
                this.snackbar.snackbar = true
                this.loadItems();
            },
            dialogueClose() {
                this.customerData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
                this.loadItems()
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                console.log()
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                };
                axios.post('Aviation/GetAirPackageListAsync', param)
                    .then(response => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        },
        mounted() {
            this.loadItems()
        },
        computed: {
            breadcrumbItems() {
                return [
                    {
                        text: this.$t('aviation_settings'),
                        disabled: false,
                        to: 'AviationSettings',
                        exact: true
                    },
                    {
                        text: this.$t('package'),
                        disabled: true,
                    }
                ]
            }
        },
        data() {
            return {
                columns: [
                    {
                        label:'s_no',
                        field: "countIndex"
                    },
                    {
                        label: 'package_name',
                        field: "packageName"
                    },
                    {
                        label:'package_type',
                        field: "packageType"
                    },
                    {
                        label: 'package_description',
                        field: "packageDescription"
                    },
                    {
                        label: 'actions',
                        field: "actions",
                        sortable: false
                    }
                ],
                rows: [],
                customerData: {
                    totalRows: 0,
                    editItemIndex: null,
                    deleteFormData: {}
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "packageID",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    dialogDelete: false,
                    dynamicComponent: {
                        current: 'AirDetailForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
