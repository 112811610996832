<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_package')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <form>
                <v-col cols="12">
                    <v-text-field v-model="packagesData.packageName"
                                  :label="$t('package_name')"
                                  :error-messages="packageNameErrors"
                                  required
                                  @input="$v.packagesData.packageName.$touch()"
                                  @blur="$v.packagesData.packageName.$touch()"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="packagesData.packageType"
                                  :label="$t('package_type')"
                                  :error-messages="packageTypeErrors"
                                  required
                                  @input="$v.packagesData.packageType.$touch()"
                                  @blur="$v.packagesData.packageType.$touch()"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="packagesData.packageDescription"
                                  :label="$t('package_description')"></v-text-field>
                </v-col>
                
                
                
            </form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157,25,25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="formSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";

    export default {
        name: "AirPackagesEditForm",
        data() {
            return {
                packagesData: {},
                message: {
                    status: true,
                    text: 'true',
                }
            }
        },
        props: ['editItemIndex'],
        computed: {
            packageNameErrors() {
                const errors = [];
                if (!this.$v.packagesData.packageName.$dirty) return errors;
                !this.$v.packagesData.packageName.required &&
                errors.push("Package Name is required.");
                return errors;
            },
            packageTypeErrors() {
                const errors = [];
                if (!this.$v.packagesData.packageType.$dirty) return errors;
                !this.$v.packagesData.packageType.required &&
                errors.push("Package Type is required.");
                return errors;
            }
        },
        validations: {
            packagesData: {
                packageName: {required},
                packageType: {required},
            }
        },
        methods: {
            dialogueClose() {
                this.packagesData = {}
                this.$emit('dialogueClose')
            },
            formSubmit() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    axios.post('Aviation/UpdateAirPackageAsync/', this.packagesData)
                        .then(() => {
                            this.message.status = true
                            this.message.text = 'Package is Successfully Updated'
                            this.$emit('formResponse', this.message)
                            this.dialogueClose()
                        })
                        .catch(() => {
                            this.message.status = false
                            this.message.text = 'Error Updating Package, Please Contact Admin'
                            this.$emit('formResponse', this.message)
                            this.dialogueClose()
                        })
                }
            },
            async getEditData() {
                const packagesData = await axios.get('Aviation/GetAirPackageByIDAsync/' + this.editItemIndex)
                this.packagesData = packagesData.data
                console.log(this.packagesData)
            },
        },
        mounted() {
            this.getEditData()
        }
    }
</script>

<style scoped>

</style>